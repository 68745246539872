<template>
  <div
    class="app-registration-form"
    data-testid="app-create-form"
  >
    <KInput
      v-model="name"
      autocomplete="off"
      class="app-registration-input"
      data-testid="app-registration-name"
      :error="nameError"
      :label="t('labels.app_name')"
      required
    />

    <KInput
      v-model="description"
      autocomplete="off"
      class="app-registration-input"
      data-testid="app-registration-description"
      :label="t('labels.description')"
    />

    <KInput
      v-if="credentialType === CREDENTIAL_TYPE.SELF_MANAGED"
      v-model="clientId"
      autocomplete="off"
      class="app-registration-input"
      data-testid="app-registration-client-id"
      :label="t('labels.client_id')"
      required
    />

    <KSelect
      v-model="authStrategy"
      class="app-registration-auth-strategy"
      data-testid="app-registration-auth-strategy"
      :disabled="authStrategiesList.length === 1"
      :items="authStrategiesList"
      :label="t('labels.auth_strategy')"
    />
    <div
      v-if="authStrategiesList.length === 1"
      class="app-registration-auth-strategy-warning"
      data-testid="app-registration-auth-strategy-warning"
    >
      {{ t('apps.form.auth_strategy_warning', { name: authStrategiesList[0]!.label }) }}
    </div>
  </div>
</template>

<script setup lang="ts">
import type { SelectItem } from '@kong/kongponents'

const props = defineProps({
  nameError: {
    type: Boolean,
    default: false,
  },
  // auth strategy id which is used byt API
  authStrategyId: {
    type: String,
    default: '',
  },
})

const { t } = useI18n()

const name = defineModel('name', { type: String, required: true })
const description = defineModel('description', { type: String, required: true })
const authStrategy = defineModel('authStrategy', { type: String, required: true })
const credentialType = defineModel<CREDENTIAL_TYPE | null>('credentialType', { required: true })
const clientId = defineModel('clientId', { type: String, required: true })

const { authStrategies } = await useAuthStrategies()

const authStrategiesList = computed((): SelectItem[] => authStrategies.value.map((el: any) => ({
  value: el.id,
  label: el.name,
})))

watch(authStrategiesList, (list) => {
  // set auth strategy ID which is used by API by default
  if (list && props.authStrategyId) {
    authStrategy.value = String(list.find(el => el.value === props.authStrategyId)?.value)
  } else if (list && list.length === 1) {
    authStrategy.value = String(list[0]!.value)
  }
}, {
  immediate: true,
})

watch(authStrategy, (value, oldValue) => {
  if (value !== oldValue) {
    const strategy = authStrategies.value.find(el => el.id === value)

    if (strategy) {
      credentialType.value = strategy.credential_type as CREDENTIAL_TYPE
    }
  }
}, {
  immediate: true,
})
</script>

<style scoped lang="scss">
.app-registration {
  &-form {
    :deep(.version-badge) {
      margin-bottom: var(--kui-space-70, $kui-space-70);
    }
  }

  &-input {
    margin-bottom: var(--kui-space-70, $kui-space-70);
  }

  &-auth-strategy-warning {
    color: var(--kui-color-text-neutral, $kui-color-text-neutral);
    font-size: var(--kui-font-size-20, $kui-font-size-20);
    line-height: var(--kui-line-height-20, $kui-line-height-20);
    margin-top: var(--kui-space-40, $kui-space-40);
  }
}
</style>

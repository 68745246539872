<template>
  <Teleport to="#teleports">
    <KModal
      data-testid="app-create-modal"
      max-width="640px"
      :title="t('apps.form.title')"
      :visible="isVisible"
      @cancel="close"
    >
      <div
        v-if="isFirstApp"
        class="app-create-description"
        data-testid="app-create-description"
      >
        {{ t('apps.form.first_app_description', { apiName: apiData?.name }) }}
      </div>

      <AppsForm
        v-model:auth-strategy="applicationForm.authStrategy"
        v-model:client-id="applicationForm.clientId"
        v-model:credential-type="credentialType"
        v-model:description="applicationForm.description"
        v-model:name="applicationForm.name"
        :auth-strategy-id="authStrategyId"
        :name-error="(application.createError && application.createError.statusCode === 409) || false"
      />

      <PortalKAlert
        v-if="errorText"
        appearance="danger"
        class="app-registration-error"
        data-testid="app-registration-error"
        :message="errorText"
        show-icon
      />

      <template #footer-actions>
        <PortalKButton
          appearance="tertiary"
          data-testid="app-create-cancel-button"
          @click="close"
        >
          {{ t('actions.cancel') }}
        </PortalKButton>
        <PortalKButton
          data-testid="app-create-proceed-button"
          :disabled="application.loader || !isFormValid"
          @click="onProceed"
        >
          {{ t('actions.create_app') }} <ArrowRightIcon />
        </PortalKButton>
      </template>
    </KModal>
  </Teleport>
</template>

<script setup lang="ts">
// This component contains a modal with App create form. It handles application creation, credential creation (optional) and registration api to created application (optional)

import { ArrowRightIcon } from '@kong/icons'

const { t } = useI18n()

const props = defineProps({
  isVisible: {
    type: Boolean,
    required: true,
  },
  // set to `true` if credentials should be created right after application is created
  withCredentials: {
    type: Boolean,
    default: true,
  },
  // set to `true` if product version should be registered to the app right after application is created
  withRegistration: {
    type: Boolean,
    default: false,
  },
  isFirstApp: {
    type: Boolean,
    default: false,
  },
  authStrategyId: {
    type: String,
    default: '',
  },
})

const emit = defineEmits<{
  (e: 'close'): void
  (e: 'app-created', newApp: PortalApiResponseTemp<'create-application'>): void
  (e: 'credential-created', newCredential: PortalApiResponseTemp<'create-credential'>): void
  (e: 'product-version-registered'): void
}>()

const credentialsId = useId()

const { createApplication, application, applicationForm, credentialType } = useApplicationCRUD()
const { createCredentials, credentials, credentialsError } = useCredentialsCRUD(credentialsId)
const { showToast } = useToast()
const { registerApiToApplication, appRegistrationError, apiData } = useAppRegistrationCRUD()

// can automatically create credentials only if App uses KEY_AUTH as an auth strategy
const canCreateCredentials = computed((): boolean => credentialType.value === CREDENTIAL_TYPE.KEY_AUTH)

const isFormValid = computed(() => {
  if (credentialType.value === CREDENTIAL_TYPE.SELF_MANAGED) {
    return !!applicationForm.value.name && !!applicationForm.value.clientId
  } else {
    return !!applicationForm.value.name
  }
})

const clearData = (): void => {
  applicationForm.value.name = ''
  applicationForm.value.description = ''
  applicationForm.value.authStrategy = ''
  applicationForm.value.clientId = ''
  application.value.createError = null
  application.value.newApp = undefined
  application.value.loader = false
  credentialsError.value = null
  appRegistrationError.value = null
  credentialType.value = null
}

watch(credentialType, (newCredentialType, oldCredentialType) => {
  if (newCredentialType !== oldCredentialType) {
    applicationForm.value.clientId = ''
  }
})

const close = (): void => {
  clearData()
  emit('close')
}

const newAppId = `new-app-create-modal-${useId()}`
const newApp = useState<PortalApiResponseTemp<'create-application'> | undefined>(newAppId, () => undefined)

const onProceed = async (): Promise<void> => {
  if (credentialType.value === CREDENTIAL_TYPE.KEY_AUTH) {
    // generate unique ID
    applicationForm.value.clientId = (Math.random() * 10000).toString()
  }

  // create a new App
  await createApplication()

  if (application.value.newApp) {
    newApp.value = application.value.newApp

    // Create a credential (optional)
    if (props.withCredentials && canCreateCredentials.value) {
      emit('app-created', newApp.value)

      await createCredentials(newApp.value?.id, newApp.value?.name)

      if (credentials.value) {
        emit('credential-created', credentials.value)

        if (!props.withRegistration) {
          clearData()
        }
      }
    }

    // Register productVersion to Created Application (optional)
    if (props.withRegistration && apiData.value?.id) {
      emit('app-created', newApp.value)

      await registerApiToApplication(newApp.value?.id, apiData.value?.id)

      if (!appRegistrationError.value) {
        clearData()

        emit('product-version-registered')
      }
    }

    // clear data and show toast if app was created without credentials or registration
    if (!props.withCredentials && !props.withRegistration && !apiData.value?.id || (!canCreateCredentials.value && !props.withRegistration)) {
      // fires if credential is not supposed to be created
      clearData()

      await showToast({
        message: t('apps.form.created_app_notification', { name: newApp.value?.name }),
      })

      emit('app-created', newApp.value)
    }
  }
}

const errorText = computed((): string => {
  if (application.value.createError) {
    return application.value.createError.statusCode === 409 ? t('errors.app_creation.conflict')
      : parseApiError(application.value.createError as any)
  }
  if (credentialsError.value) {
    return parseApiError(credentialsError.value as any)
  }
  if (appRegistrationError.value) {
    return parseApiError(appRegistrationError.value as any)
  }

  return ''
})
</script>

<style scoped lang="scss">
.app-registration {
  &-error {
    margin-top: var(--kui-space-70, $kui-space-70);
  }
}

.app-create {
  &-description {
    margin-bottom: var(--kui-space-70, $kui-space-70);
  }
}
</style>

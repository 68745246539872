const DEFAULT_PAGE_SIZE = 100

export default async function useAuthStrategies() {
  // List queries
  const pageNumber = useState<number>(() => 1)
  const pageSize = useState<number>(() => DEFAULT_PAGE_SIZE)

  const { data: authStrategiesRawData, error: authStrategiesError } = await usePortalApi(
    '/api/v3/application-auth-strategies', {
      query: {
        // @ts-ignore: nuxt-open-fetch query interface is not correctly typed for reactive values
        'page[number]': pageNumber,
        // @ts-ignore: nuxt-open-fetch query interface is not correctly typed for reactive values
        'page[size]': pageSize,
      },
      watch: [pageSize, pageNumber],
      key: 'auth-strategies-list',
    },
  )

  const authStrategies = computed((): PortalApiResponseTemp<'list-application-auth-strategies'>['data'] => authStrategiesRawData.value?.data || [])

  return {
    authStrategies,
    authStrategiesError,
  }
}

<template>
  <Teleport to="#teleports">
    <KModal
      class="apps-success-modal"
      data-testid="apps-success-modal"
      max-width="640px"
      :title="t('labels.success')"
      :visible="isVisible"
      @cancel="onProceed"
    >
      <PortalKAlert
        appearance="info"
        class="apps-success-info"
        data-testid="apps-success-info"
        :message="t('apps.form.success_message')"
        show-icon
      />
      <div class="apps-success-content">
        <AppsCard
          v-if="app"
          :app="app"
          class="apps-success-card"
          data-testid="apps-success-card"
          :show-menu="false"
        />

        <AppsCredentialForm
          v-if="credentials"
          v-model:name="credentialsName"
          :credentials="credentials"
        />

        <PortalKAlert
          v-if="errorText"
          appearance="danger"
          class="apps-success-alert"
          data-testid="apps-success-alert"
          :message="errorText"
          show-icon
        />
      </div>

      <template
        v-if="credentials"
        #footer-actions
      >
        <PortalKButton
          v-if="changesExist"
          appearance="secondary"
          data-testid="app-success-modal-cancel-button"
          @click="emit('close')"
        >
          {{ t('actions.cancel') }}
        </PortalKButton>

        <PortalKButton
          v-if="changesExist"
          data-testid="app-success-modal-update-credentials-button"
          :disabled="loading || !credentialsName"
          @click="onProceed"
        >
          {{ t('actions.update_credentials') }}
        </PortalKButton>
        <CopyButton
          v-else-if="credentials"
          button-tag="PortalKButton"
          data-testid="app-success-modal-copy-button"
          :show-tooltip="false"
          :text="credentials.credential"
          @copied="onProceed"
        >
          {{ t('actions.copy_and_close') }}
        </CopyButton>
      </template>

      <template
        v-else
        #footer-actions
      >
        <PortalKButton
          data-testid="app-success-modal-close-button"
          @click="emit('close')"
        >
          {{ t('actions.close') }}
        </PortalKButton>
      </template>
    </KModal>
  </Teleport>
</template>

<script setup lang="ts">
// This component contains a success modal if credentials were created right after the application was created. Contains App card and Credentials form. Handles adding a custom name to credentials (optional)

const credentialsId = useId()

const { t } = useI18n()
const { credentialsName, updateCredentials, credentialsError } = useCredentialsCRUD(credentialsId)

const props = defineProps({
  isVisible: {
    type: Boolean,
    default: false,
  },
  credentials: {
    type: [Object, null] as PropType<PortalApiResponseTemp<'create-credential'> | null>,
    required: true,
  },
  app: {
    type: [Object, null] as PropType<PortalApiResponseTemp<'create-application'> | null>,
    required: true,
  },
})

const emit = defineEmits<{
  (e: 'close'): void
  (e: 'success'): void
}>()

const changesExist = computed((): boolean => {
  const originalName = props.credentials?.display_name === props.credentials?.id ? '' : props.credentials?.display_name

  return credentialsName.value !== originalName
})

const errorText = computed((): string => credentialsError.value ? parseApiError(credentialsError.value as any) : '')
const loading = useState<boolean>(() => false)

const onProceed = async (): Promise<void> => {
  if (changesExist.value) {
    try {
      loading.value = true

      await updateCredentials(props.app?.id || '', props.credentials?.id || '')

      if (!credentialsError.value) {
        emit('success')
      }
    } finally {
      loading.value = false
    }
  } else {
    // Should be emitted to navigate user to details page
    emit('success')
  }
}
</script>

<style scoped lang="scss">
.apps-success {
  &-modal {
    :deep(.modal-content) {
      padding: var(--kui-space-0, $kui-space-0) !important;
    }
  }

  &-info {
    border: var(--kui-border-width-0, $kui-border-width-0);
    margin-top: var(--kui-space-0, $kui-space-0) !important;
  }

  &-content {
    padding: var(--kui-space-80, $kui-space-80);
  }

  &-card {
    margin-bottom: var(--kui-space-70, $kui-space-70);
  }

  &-alert {
    margin-top: var(--kui-space-70, $kui-space-70);
  }
}
</style>

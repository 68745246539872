<template>
  <PortalCard
    class="app-card"
    data-testid="app-card"
  >
    <template
      v-if="app.name"
      #title
    >
      <div data-testid="app-name">
        <PortalKButton
          appearance="none"
          :to="{
            name: 'apps-app_id',
            params: {
              app_id: app.id,
            },
          }"
        >
          <h2
            class="app-card-title"
            data-testid="app-card-title"
          >
            {{ app.name }}
          </h2>
        </PortalKButton>
      </div>
    </template>

    <template
      v-if="showMenu"
      #actions
    >
      <KDropdown :kpop-attributes="{ placement: 'bottom-end' }">
        <PortalKButton
          appearance="secondary"
          icon
          size="small"
        >
          <MoreIcon :title="t('actions.more_actions')" />
        </PortalKButton>

        <template #items>
          <slot
            :app="app"
            name="dropdown-items"
          >
            <KDropdownItem
              :item="{ label: t('actions.view_details'), to: {
                name: 'apps-app_id',
                params: {
                  app_id: app.id,
                },
              } }"
            />
            <KDropdownItem
              danger
              has-divider
              @click="$emit('delete-app', app.id)"
            >
              {{ t('actions.delete_application') }}
            </KDropdownItem>
          </slot>
        </template>
      </KDropdown>
    </template>

    <div
      v-if="app.description"
      data-testid="app-card-description"
    >
      {{ app.description }}
    </div>

    <template #footer>
      <div
        class="app-card-footer"
        data-testid="app-card-footer"
      >
        <div
          class="app-card-footer-actions"
          data-testid="footer-actions"
        >
          <div class="app-card-auth-strategy">
            <template v-if="showAuthStrategy">
              <span
                v-if="simple"
                class="app-card-auth-strategy-label"
                data-testid="app-card-auth-strategy-label"
              >{{ t('labels.auth_strategy') }}</span>

              <PortalKBadge
                v-if="authStrategyName"
                appearance="neutral"
                data-testid="app-card-auth-strategy-badge"
              >
                {{ authStrategyName }}
              </PortalKBadge>
            </template>
          </div>

          <slot
            :app="app"
            name="footer-actions"
          />
        </div>

        <slot
          :app="app"
          name="footer-bottom"
        />
      </div>
    </template>
  </PortalCard>
</template>

<script setup lang="ts">
// This component displays app information
import { MoreIcon } from '@kong/icons'

const props = defineProps({
  app: {
    type: Object as PropType<PortalApiResponseTemp<'get-application'> | PortalApiResponseTemp<'create-application'>>,
    required: true,
  },
  // changes footer appearance if true
  simple: {
    type: Boolean,
    default: false,
  },
  /** Show or hide actions menu */
  showMenu: {
    type: Boolean,
    default: true,
  },
  /** Show or hide auth strategy */
  showAuthStrategy: {
    type: Boolean,
    default: true,
  },
})

defineEmits<{
  (e: 'delete-app', appId: string): void
}>()

const { t } = useI18n()

const authStrategyName = computed((): string => {
  if ('auth_strategy' in props.app && props.app.auth_strategy) {
    return 'name' in props.app.auth_strategy ? props.app.auth_strategy.name : ''
  }

  return ''
})
</script>

<style scoped lang="scss">
.app-card {
  gap: var(--kui-space-40, $kui-space-40);

  &-footer-actions {
    align-items: center;
    display: flex;
    justify-content: space-between;
    width: 100%;
  }

  &-auth-strategy {
    align-items: center;
    display: flex;
    gap: var(--kui-space-40, $kui-space-40);

    &-label {
      color: var(--kui-color-text-neutral, $kui-color-text-neutral);
      font-size: var(--kui-font-size-20, $kui-font-size-20);
    }
  }
}
</style>

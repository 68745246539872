import { watchDebounced } from '@vueuse/core'

interface appsOptions {
  defaultPageSize?: number
  authStrategyFilterId?: string
}

const DEFAULT_PAGE_SIZE = 9

export default async function useApps(options: appsOptions = { defaultPageSize: DEFAULT_PAGE_SIZE }) {
  // Apps List
  const { defaultPageSize, authStrategyFilterId } = options

  // List queries
  const pageNumber = useState<number>(() => 1)
  const pageSize = useState<number>(() => defaultPageSize || DEFAULT_PAGE_SIZE)
  // is used in query object
  const nameQuery = useState<string>(() => '')
  // is used as a model for input
  const searchName = useState<string>(() => '')

  const query = computed(() => ({
    'page[number]': pageNumber.value,
    'page[size]': pageSize.value,
    'filter[name][contains]': nameQuery.value ? nameQuery.value : undefined,
    'filter[auth_strategy_id][eq]': authStrategyFilterId ? authStrategyFilterId : undefined,
  }))

  // should be ref (not computed) to display a proper empty state / no results state
  const appsExist = computed((): boolean => nameQuery.value ? true : appsTotal.value > 0)

  const { data: appsRawData, error: appsError, refresh: appsRefresh, status: appsStatus } = await usePortalApi(
    '/api/v3/applications', {
      query,
      watch: [pageSize, pageNumber, nameQuery],
    },
  )

  const apps = computed((): PortalApiResponseTemp<'get-application'>[] => appsRawData.value?.data || [])
  const appsTotal = computed((): number => appsRawData.value?.meta?.page?.total || 0)

  const noResults = computed((): boolean => !!nameQuery.value && appsTotal.value === 0)

  watchDebounced(searchName, (val) => {
    nameQuery.value = val
    pageNumber.value = 1
  }, {
    debounce: 1000,
  })

  /**
   * Sets all pagination params and search queries to default
   * @returns {void}
   */
  const resetQueries = (): void => {
    pageSize.value = defaultPageSize || DEFAULT_PAGE_SIZE
    pageNumber.value = 1
    nameQuery.value = ''
    searchName.value = ''
  }

  /**
   * Refetch apps list
   * @returns {Promise<void>}
   */
  const fetchApps = async (): Promise<void> => {
    await appsRefresh({ dedupe: 'defer' })
  }

  return {
    apps,
    appsExist,
    appsError,
    pageNumber,
    pageSize,
    appsTotal,
    searchName,
    noResults,
    resetQueries,
    fetchApps,
    appsStatus,
  }
}

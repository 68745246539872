<template>
  <div>
    <AppsCreateModal
      :auth-strategy-id="authStrategyId"
      :is-first-app="isFirstApp"
      :is-visible="createOpen"
      :with-credentials="withCredentials"
      :with-registration="withRegistration"
      @app-created="appCreatedHandler($event)"
      @close="$emit('close')"
      @credential-created="credentialCreatedHandler($event)"
      @product-version-registered="showSuccessModal"
    />

    <AppsCreateSuccessModal
      :app="newApp"
      :credentials="newCredentials"
      :is-visible="successModalVisibility"
      @close="onSuccess"
      @success="onSuccess"
    />
  </div>
</template>

<script setup lang="ts">
const props = defineProps({
  isVisible: {
    type: Boolean,
    required: true,
  },
  // set to `true` if credentials should be created right after application is created
  withCredentials: {
    type: Boolean,
    default: true,
  },
  // set to `true` if product version should be registered to the app right after application is created
  withRegistration: {
    type: Boolean,
    default: false,
  },
  isFirstApp: {
    type: Boolean,
    default: false,
  },
  authStrategyId: {
    type: String,
    default: '',
  },
})

const emit = defineEmits<{
  (e: 'close'): void
  (e: 'success', appId: string): void
}>()

const { t } = useI18n()
const { showToast } = useToast()

const id = useId()

const createOpen = useState<boolean>(`create-open-${id}`,() => false)

watch(() => props.isVisible, (isVisible) => {
  createOpen.value = isVisible
}, {
  immediate: true,
})

const successModalVisibility = useState<boolean>(`apps-create-success-modal-visibility-${id}`, () => false)
const newCredentials = useState<PortalApiResponseTemp<'create-credential'> | null>(`apps-create-new credentials-${id}`, () => null)
const newApp = useState<PortalApiResponseTemp<'create-application'> | null>(`apps-create-new-created-app-${id}`, () => null)

const showSuccessModal = (): void => {
  createOpen.value = false
  successModalVisibility.value = true
}

const successModalClose = (): void => {
  successModalVisibility.value = false
  emit('close')
}

const onSuccess = (): void => {
  successModalClose()
  emit('success', newApp.value?.id || '')
}

const appCreatedHandler = (app: PortalApiResponseTemp<'create-application'>): void => {
  newApp.value = app

  if ((!props.withCredentials || newApp.value?.auth_strategy?.credential_type as unknown !== CREDENTIAL_TYPE.KEY_AUTH) && !props.withRegistration) {
    createOpen.value = false
    emit('success', newApp.value!.id)
  } else {
    showToast({
      message: t('apps.form.create_notification', { appName: newApp.value?.name }),
    })
  }
}

const credentialCreatedHandler = (credential: PortalApiResponseTemp<'create-credential'>): void => {
  newCredentials.value = credential

  if (!props.withRegistration) {
    showSuccessModal()
  }
}

onBeforeUnmount(() => {
  createOpen.value = false
  successModalVisibility.value = false
})
</script>

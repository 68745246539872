<template>
  <div>
    <LoadingCard v-if="appsStatus === 'pending'" />

    <AppsEmptyState
      v-else-if="!appsExist && !noResults && !errorText"
      @click:action="$emit('create-app')"
    />

    <div
      v-else
      class="apps-list-container"
    >
      <div
        class="apps-list-filters-row"
      >
        <!--      <KSelect-->
        <!--        v-model="selectedStatus"-->
        <!--        class="apps-list-status"-->
        <!--        :items="statusOptions"-->
        <!--      />-->

        <KInput
          v-model.trim="searchName"
          class="apps-list-search-input"
          :placeholder="t('apps.list.search_placeholder')"
          type="search"
        >
          <template #before>
            <FilterIcon />
          </template>
          <template #after>
            <button
              v-if="searchName"
              :aria-label="t('actions.clear_search')"
              :title="t('actions.clear_search')"
              type="button"
              @click="searchName = ''"
            >
              <CloseIcon decorative />
            </button>
          </template>
        </KInput>

        <slot name="top-actions" />
      </div>

      <div v-if="noResults">
        <KEmptyState
          class="apps-list-no-results"
          icon-variant="search"
          :title="t('apps.list.no_results_title')"
        />
      </div>

      <template v-else>
        <PortalKAlert
          v-if="errorText"
          appearance="danger"
          class="apps-list-error"
          :message="errorText"
          show-icon
        />

        <PageContainer
          class="apps-list-grid"
          display="grid"
          :grid-columns="gridColumns"
          :grid-columns-breakpoints="gridColumnBreakpoints"
        >
          <AppsCard
            v-for="app in apps"
            :key="app.id"
            :app="app as PortalApiResponseTemp<'get-application'>"
            :show-auth-strategy="showAuthStrategy"
            :simple="simpleCard"
            @delete-app="openDeleteConfirmation(app as PortalApiResponseTemp<'get-application'>)"
          >
            <template #footer-actions="{ app: cardApp }">
              <slot
                :app="cardApp"
                name="footer-actions"
              />
            </template>
            <template #footer-bottom="{ app: cardApp }">
              <slot
                :app="cardApp"
                name="footer-bottom"
              />
            </template>
            <template #dropdown-items="{ app: cardApp }">
              <slot
                :app="cardApp"
                name="dropdown-items"
              />
            </template>
          </AppsCard>
        </PageContainer>

        <KPagination
          v-if="appsStatus === 'success'"
          :current-page="pageNumber"
          :page-sizes="pageSizes"
          :total-count="appsTotal"
          @page-change="setPage"
          @page-size-change="setPageSize"
        />

        <AppsDeleteConfirmation
          v-if="appToDelete"
          :application-id="appToDelete.id"
          :is-visible="showDeleteConfirmation"
          :name="appToDelete.name"
          @close="showDeleteConfirmation = false"
          @delete="refreshList"
        />
      </template>
    </div>
  </div>
</template>

<script setup lang="ts">
import type { PageChangeData, PageSizeChangeData /*, SelectItem */ } from '@kong/kongponents'
import type { Breakpoints } from '#imports'
import { FilterIcon, CloseIcon } from '@kong/icons'
import type { PropType } from 'vue'

const props = defineProps({
  gridColumns: {
    type: Number,
    default: 1,
  },
  gridColumnBreakpoints: {
    type: Object as PropType<Breakpoints>,
    default: () => ({ desktop: 3, laptop: 3, tablet: 3, phablet: 2, mobile: 1 }),
  },
  pageSizes: {
    type: Array as PropType<number[]>,
    default: () => [9, 18, 27, 36],
  },
  simpleCard: {
    type: Boolean,
    default: false,
  },
  /** Show or hide auth strategy in Apps Card */
  showAuthStrategy: {
    type: Boolean,
    default: true,
  },
  /** Auth strategy id to filter Apps List */
  authStrategyFilterId: {
    type: String,
    default: '',
  },
})

const { t } = useI18n()
const {
  apps,
  appsExist,
  searchName,
  appsTotal,
  pageSize,
  pageNumber,
  noResults,
  appsError,
  resetQueries,
  fetchApps,
  appsStatus,
} = await useApps({ defaultPageSize: props.pageSizes[0], authStrategyFilterId: props.authStrategyFilterId })
const { showToast } = useToast()

// force update page size to prevent using a number from other pages
pageSize.value = props.pageSizes[0] || 9

const emit = defineEmits<{
  (e: 'apps-exist', isExist: boolean): void
  (e: 'create-app'): void
}>()

watch(appsExist, (isExist, oldIsExist) => {
  if (isExist !== oldIsExist) {
    emit('apps-exist', isExist)
  }
}, {
  immediate: true,
})

// const statusOptions = useState<SelectItem[]>(() => [
//   {
//     value: AppsStateOptions.All,
//     label: t('apps_filters.all'),
//   },
//   {
//     value: AppsStateOptions.Registered,
//     label: t('apps_filters.registered'),
//   },
//   {
//     value: AppsStateOptions.Available,
//     label: t('apps_filters.available'),
//   },
//   {
//     value: AppsStateOptions.Pending,
//     label: t('apps_filters.pending'),
//   },
//   {
//     value: AppsStateOptions.Incompatible,
//     label: t('apps_filters.incompatible'),
//   },
//   {
//     value: AppsStateOptions.Rejected,
//     label: t('apps_filters.rejected'),
//   },
//   {
//     value: AppsStateOptions.Revoked,
//     label: t('apps_filters.revoked'),
//   },
// ])
//
// const selectedStatus = useState<AppsStateOptions>(() => AppsStateOptions.All)

const setPage = (event: PageChangeData) => {
  pageNumber.value = event.page
}
const setPageSize = (event: PageSizeChangeData) => {
  pageSize.value = event.pageSize
}

const errorText = computed((): string => appsError.value ? parseApiError(appsError.value as any) : '')

const showDeleteConfirmation = useState<boolean>(() => false)
const appToDelete = useState<PortalApiResponseTemp<'get-application'> | null>(() => null)

const openDeleteConfirmation = (app: PortalApiResponseTemp<'get-application'>):void => {
  appToDelete.value = app

  showDeleteConfirmation.value = true
}

const refreshList = async (): Promise<void> => {
  showDeleteConfirmation.value = false

  showToast({
    message: t('apps.delete.success_message', { appName: appToDelete.value?.name }),
  })

  // Important: await a DOM update to ensure modal overflow class is removed from the body tag
  await nextTick()

  appToDelete.value = null

  if (pageNumber.value !== 1) {
    // triggers fetch applications
    pageNumber.value = 1
  }

  await fetchApps()
}

onBeforeUnmount(() => {
  resetQueries()
})
</script>

<style scoped lang="scss">
.apps-list {
  &-error {
    margin-top: var(--kui-space-70, $kui-space-70);
  }

  &-no-results {
    margin-top: var(--kui-space-70, $kui-space-70);
  }

  &-filters-row {
    display: flex;
    flex-direction: column;
    gap: var(--kui-space-70, $kui-space-70);
    width: 100%;

    @media (min-width: $kui-breakpoint-phablet) {
      align-items: center;
      flex-direction: row;
    }
  }

  &-status {
    @media (min-width: $kui-breakpoint-phablet) {
      width: fit-content;
    }
  }

  &-search-input {
    :deep(input[type="search"]) {
      &::-webkit-search-cancel-button {
        display: none;
      }
    }
  }

  &-grid {
    margin: var(--kui-space-70, $kui-space-70) var(--kui-space-0, $kui-space-0);
  }
}
</style>
